import { i18n } from 'next-i18next.config';

import { LineItem } from '@commerce/types/cart';
import { Product } from '@commerce/types/product';
import { ProductBrand, ProductType } from '@components/product/enums';
import { getPremiumMap, getProductsByIds, isJiobit } from '@components/product/helpers';
import { SubscriptionInfo } from '@components/product/types/SubscriptionMapping';
import IneligibleMapping from '@config/upsell-ineligible-mapping.json';
import { originalLocaleFormat, SUPPORTED_LOCALES } from '@lib/locales';
import roundDecimal from '@lib/round-decimal';

import { LineItemBreakdown } from './types/LineItemBreakdown';
import { PremiumData } from './types/PremiumData';
import { Prices } from './types/Prices';

const giftEmailLocales = [SUPPORTED_LOCALES.EN_AU, SUPPORTED_LOCALES.EN_NZ];

function getProductsByType(lineItems: LineItem[], productType: string) {
  return lineItems.filter((item) => item.type === productType);
}

function getPremiumLineItem(lineItems: LineItem[] | null | undefined, locale?: string): LineItem | null {
  if (lineItems && lineItems.length > 0) {
    return getProductsByType(lineItems, 'Digital').find(({ variant: { sku } }) => isPremiumSKU(sku, locale)) || null;
  }

  return null;
}

export function getSubscriptionInfoFromSKU(sku?: string | null, locale?: string): SubscriptionInfo | null {
  if (sku) {
    const { tier, term, type, brand } = getPremiumMap(locale)[sku] ?? {};

    if (!tier || !term || !type || !brand) {
      return null;
    }

    return { tier, term, type, brand };
  }

  return null;
}

export function isPremiumLineItem(lineItem: LineItem, locale?: string): boolean {
  return lineItem.type === ProductType.DIGITAL && isPremiumSKU(lineItem.variant.sku, locale);
}

export function isPremiumSKU(sku: string, locale?: string): boolean {
  const premiumMap = getPremiumMap(locale);
  return Object.keys(premiumMap).includes(sku);
}

export function isL360PremiumSKU(sku: string, locale?: string): boolean {
  const premiumMap = getPremiumMap(locale);
  return Object.keys(premiumMap)
    .filter((key) => premiumMap[key].brand === ProductBrand.L360)
    .includes(sku);
}

export function acceptsGiftEmail(locale: string): boolean {
  return !!giftEmailLocales.find((v) => v === locale);
}

export async function getSkuToProductMapping(
  ids: number[],
  locale = i18n.defaultLocale
): Promise<Record<string, Product>> {
  const products = (await getProductsByIds(ids, locale)) as Product[];
  return products.length > 0 ? getProductSKUMapping(products) : {};
}

export function getPremiumDataInCart(
  lineItems: LineItem[],
  skuToProductMap: Record<string, Product>,
  locale = i18n.defaultLocale
): Required<PremiumData> | null {
  const {
    productId: id,
    id: lineItemId,
    variant: { sku: premiumSKU },
  } = getPremiumLineItem(lineItems, locale) || { variant: {} };

  const subscriptionInfo = getSubscriptionInfoFromSKU(premiumSKU, locale);

  return id && lineItemId && subscriptionInfo
    ? {
        id,
        lineItemId,
        ...subscriptionInfo,
        originalPrice: (premiumSKU && skuToProductMap[premiumSKU]?.prices?.retailPrice?.value) || 0,
      }
    : null;
}

export function getPremiumData(
  premiumLineItem: LineItem,
  skuToProductMap: Record<string, Product>,
  locale = i18n.defaultLocale
): Required<PremiumData> | null {
  const {
    productId: id,
    id: lineItemId,
    variant: { sku: premiumSKU },
  } = premiumLineItem;

  const subscriptionInfo = getSubscriptionInfoFromSKU(premiumSKU, locale);

  return id && lineItemId && subscriptionInfo
    ? {
        id,
        lineItemId,
        ...subscriptionInfo,
        originalPrice: (premiumSKU && skuToProductMap[premiumSKU]?.prices?.retailPrice?.value) || 0,
      }
    : null;
}

export function getPrices(subTotalAmt?: number | null, discountAmt?: number | null): Prices {
  const discount = typeof discountAmt === 'number' ? discountAmt : undefined;
  const subtotal = Number(subTotalAmt) || 0;
  const total = subtotal - (discount || 0);
  return {
    total,
    subtotal,
    discount,
  };
}

export function areAllLineItemsDigital(lineItems?: LineItem[]) {
  if (!lineItems || lineItems.length === 0) {
    return false;
  }

  return !!lineItems.every(({ type }) => type === ProductType.DIGITAL);
}

export function areAllLineItemsPremium(lineItems?: LineItem[], locale?: string) {
  if (!lineItems || lineItems.length === 0) {
    return false;
  }

  return !!lineItems.every(({ variant: { sku } }) => isPremiumSKU(sku, locale));
}

export function isFreeShipping(totalCartPrice: number, freeShippingPrice: number | null) {
  return !!freeShippingPrice && totalCartPrice > freeShippingPrice;
}

/**
 * check if cart lineItem is OOS or insufficient stock (quantity requested more than quantity available)
 * @param item
 * @param product
 * @returns { outOfStock}
 */
export function isItemOosOrInsufficient(item: LineItem, product?: Product): 'oos' | 'insufficient' | false {
  if (product && item.type === ProductType.PHYSICAL) {
    const isInStock = !!product.inventory?.isInStock;
    if (!isInStock) {
      return 'oos';
    }

    if (item.quantity === 1 && isInStock) {
      return false;
    }

    // in case BigC inventory stock level settings set to not allow showing inventory level
    // when isInStock flag is true, default to 1
    const availableToSell = product.inventory?.aggregated?.availableToSell ?? 1;
    if (item.quantity > availableToSell) {
      return 'insufficient';
    }
  }
  return false;
}

export type StockMap = {
  outOfStockMap: Record<string, string>;
  insufficientStockMap: Record<string, { name: string; available: number }>;
};

export function getOosAndInsufficientStockMap(lineItems: LineItem[], productMap: Record<string, Product>): StockMap {
  if (lineItems.length > 0 && productMap) {
    return lineItems.reduce(
      (acc, item) => {
        const { sku } = item.variant;
        const status = isItemOosOrInsufficient(item, productMap[sku]);
        switch (status) {
          case 'oos':
            acc.outOfStockMap[sku] = item.name;
            break;
          case 'insufficient':
            acc.insufficientStockMap[sku] = {
              name: item.name,
              available: productMap[sku]?.inventory?.aggregated?.availableToSell || 1,
            };
            break;
          default:
            break;
        }
        return acc;
      },
      { outOfStockMap: {}, insufficientStockMap: {} } as StockMap
    );
  }
  return { outOfStockMap: {}, insufficientStockMap: {} };
}

export function getProductSKUMapping(products: Product[]) {
  return products.reduce((acc, product) => {
    if (product.sku) {
      acc[product.sku] = product;
    }
    return acc;
  }, {} as Record<string, Product>);
}

export function getMsrpDiscounts(lineItems: LineItem[], skuToProductMap: Record<string, Product>): number {
  if (lineItems.length > 0) {
    const msrpSavings = lineItems.reduce((acc, { quantity, type, variant: { sku, price, listPrice } }) => {
      if (type === ProductType.PHYSICAL) {
        const { prices } = skuToProductMap[sku] || {};
        const msrp = prices?.retailPrice?.value || listPrice;
        const savingPerItem = msrp - price;
        return acc + quantity * savingPerItem;
      }
      return acc;
    }, 0);

    return roundDecimal(msrpSavings);
  }
  return 0;
}

export function hasJiobitProducts(lineItems: LineItem[], skuToProductMap: Record<string, Product>): boolean {
  if (lineItems.length > 0) {
    return lineItems.some(({ variant: { sku } }) => {
      const product = skuToProductMap[sku];
      return isJiobit(product?.brand || ProductBrand.TILE);
    });
  }
  return false;
}

export const getIneligibleHWMapping = (locale?: string): string[] =>
  (locale && IneligibleMapping[originalLocaleFormat(locale)]) || IneligibleMapping.default || [];

export function isItemEligibleForPremiumUpsell(sku: string, locale?: string): boolean {
  const ineligibleHWMapping = getIneligibleHWMapping(locale);
  return ineligibleHWMapping.indexOf(sku) === -1;
}

export const getLineItemBreakdown = (lineItems: LineItem[], locale = i18n.defaultLocale): LineItemBreakdown =>
  lineItems.reduce(
    (acc, lineItem) => {
      const { sku } = lineItem.variant;
      if (isPremiumLineItem(lineItem, locale)) {
        return { ...acc, premium: lineItem };
      }
      if (!isItemEligibleForPremiumUpsell(sku, locale)) {
        return { ...acc, others: [...acc.others, lineItem] };
      }
      return { ...acc, premiumEligible: [...acc.premiumEligible, lineItem] };
    },
    { premiumEligible: [] as LineItem[], others: [], premium: null } as LineItemBreakdown
  );
